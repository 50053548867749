import React from 'react';

function NameIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      fill="none"
      viewBox="0 0 13 12"
    >
      <path
        fill="#29ABE2"
        d="M6.632 11.354H1.44c-.56 0-.666-.139-.633-.688A3.924 3.924 0 014.82 7.028h3.627a3.9 3.9 0 013.96 3.231c.032.18.053.362.061.545.026.38-.122.545-.5.548-.66.006-1.319 0-1.979 0l-3.357.002zM6.614.045a3.117 3.117 0 11-.006 6.234A3.117 3.117 0 016.614.045z"
      />
    </svg>
  );
}

export default NameIcon;
