import React from 'react';
import LandingLayout from '../components/layout/LandingLayout';
import LoginForm from '../components/LoginForm';

function LoginPage() {
  return (
    <LandingLayout>
      <LoginForm />
    </LandingLayout>
  );
}

export default LoginPage;
