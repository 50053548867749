/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Link from 'next/link';
import Router from 'next/router';
import NProgress from 'nprogress';
import NameIcon from './icons/NameIcon';
import PasswordIcon from './icons/PasswordIcon';
import firebase from '../lib/firebase/client';
import useUser from '../hooks/useUser';
import { User } from '../models';

// TODO: Refactor
function LoginForm() {
  const [userEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [, setUser] = useUser();
  const loginHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (disabled) return;
      NProgress.start();
      setDisabled(false);
      const userLogged = await firebase
        .auth()
        .signInWithEmailAndPassword(userEmail, password);
      const user = (
        await firebase
          .firestore()
          .collection('users')
          .doc(userLogged?.user?.uid)
          .get()
      ).data();
      setUser((user as unknown) as User);
      Router.push('/notifications');
    } catch (err) {
      alert(err.message);
    } finally {
      NProgress.done();
      setDisabled(false);
    }
  };

  return (
    <div className="flex flex-col pt-32 text-center w-full justify-start items-center">
      <form
        action="/clients"
        method="POST"
        onSubmit={loginHandler}
        className="w-full flex flex-col justify-items-center items-center"
      >
        <h2 className="text-white uppercase text-4xl">Login</h2>
        <div className="pt-10 w-full flex flex-col justify-items-center items-center">
          <label htmlFor="name" className="sr-only">
            Correo electrónico
          </label>
          <div className="flex flex-row justify-start items-center h-8 mb-4 border-b-2 border-secondary-500 md:w-1/4 w-5/6">
            <NameIcon />
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Correo electrónico"
              className="appearance-none bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:ring-0 focus:outline-none text-sm bg-none"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <label htmlFor="password" className="sr-only">
            Contraseña
          </label>
          <div className="flex flex-row justify-start items-center h-8 mb-4 border-b-2 border-secondary-500 md:w-1/4 w-5/6">
            <PasswordIcon />
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Contraseña"
              className="appearance-none bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:ring-0 focus:outline-none text-sm bg-none"
              onChange={(e) => {
                e.preventDefault();
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col justify-start items-center md:w-1/4 w-5/6">
          <div className="flex flex-row h-8 self-start items-center pb-4 ">
            <input
              className="form-checkbox text-secondary-500 border-2 border-secondary-500 bg-transparent cursor-pointer focus:ring-0"
              type="checkbox"
              name="remember"
              value="yes"
            />
            <span className="text-xs leading-normal text-secondary-500 pl-2">
              Recuérdame
            </span>
          </div>
          <button
            type="submit"
            className="bg-secondary-500 hover:bg-secondary-400 text-primary-500 text-base leading-normal rounded-full uppercase bebas w-32 h-9 mb-4 mt-2 cursor-pointer"
            disabled={disabled}
          >
            Sign In
          </button>
        </div>
      </form>
      <Link href="/reset-password">
        <a className="text-xs leading-normal text-secondary-500 mb-40">
          ¿Olvidaste tu contraseña?
        </a>
      </Link>
    </div>
  );
}

export default LoginForm;
