import React from 'react';

function PasswordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="14"
      fill="none"
      viewBox="0 0 10 14"
    >
      <path
        fill="#29ABE2"
        d="M8.37 6.164H.893a.876.876 0 00-.876.875v5.185a.876.876 0 00.876.875H8.37a.875.875 0 00.875-.875V7.039a.875.875 0 00-.875-.875zM5.841 8.598a1.06 1.06 0 01-.538.892.157.157 0 00-.079.213c.193.71.378 1.421.566 2.133.016.061.029.124.048.2H3.432a175.76 175.76 0 01.526-2.393.176.176 0 00-.07-.211 1.081 1.081 0 01-.414-1.2 1.193 1.193 0 011.046-.814A1.17 1.17 0 015.841 8.6v-.002zM2.192 5.686V3.977a1.874 1.874 0 01.6-1.416 2.756 2.756 0 013.451-.188 1.955 1.955 0 01.831 1.668c-.009.548 0 1.1 0 1.645h1.1c0-.554-.006-1.108 0-1.662a2.743 2.743 0 00-.876-2.071 4.013 4.013 0 00-4.808-.42A2.824 2.824 0 001.08 4.107c.015.526 0 1.052 0 1.579h1.113z"
      />
    </svg>
  );
}

export default PasswordIcon;
