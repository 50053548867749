import React from 'react';

interface LandingLayoutProps {
  children?: React.ReactNode;
}

export default function LandingLayout({ children }: LandingLayoutProps) {
  return (
    <div className="flex flex-col justify-start items-center bg-primary-500 w-full min-h-screen">
      <div className="flex justify-start items-center p-12 h-32 w-full">
        <img
          src="/bloopexlogo.png"
          alt="dark bloop logo"
          className="h-14 mt-3"
        />
      </div>
      {children}
    </div>
  );
}
